/* Search cancel button */

input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
  height: 1.2em;
  width: 1.2em;
  background: url("/assets/icons/close-btn-red.svg") no-repeat 50% 50%;
  background-size: contain;
  opacity: 0;
  pointer-events: none;
}

input[type="search"]:focus::-webkit-search-cancel-button {
  opacity: 1;
  pointer-events: all;
}

.form-group .form-control,
.form-group .form-control:focus {
  color: rgba(0, 0, 0, 0.87);
}

.search-wrapper .form-control:focus {
  color: rgba(15, 10, 12, 0.87);
  font-weight: 500;
  box-shadow: none;
  border: 0;
}

.focused,
.search-wrapper.focused {
  border: 1px solid #6a58a2;
  box-shadow: 0 0 0 0.2rem rgba(52, 43, 79, 0.25);
  border-radius: 0.25rem;
}

.focused.focused.input-group .form-control,
.focused.input-group .input-group-prepend .input-group-text,
.focused.input-group .input-group-append .input-group-text {
  border: 0;
  box-shadow: none;
}

.clear-input-button {
  -webkit-appearance: none;
  appearance: none;
  height: 1.2em;
  width: 1.2em;
  background: url("/assets/icons/close-btn-red.svg") no-repeat 50% 50%;
  background-size: contain;
  border: none;
  margin: 0;
  padding: 2px;
  opacity: 0;
  pointer-events: none;
}

.clear-input-button:hover {
  border-radius: 50%;
  background-color: rgba(230, 7, 30, 0.16);
  opacity: 1;
}

.clear-input-button.show {
  opacity: .87;
  pointer-events: all;
}