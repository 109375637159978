// Add Chippix Theme
@import "../src/themes/deafult/variables";
@import "~bootstrap/scss/bootstrap";
@import "../src/themes/deafult/bootswatch";
@import url("assets/icons/fonts/fonts-material-icons.scss");
@import url("app/shared/styles/inputs.scss");

.material-symbols-outlined {
  font-family: 'Material Symbols Outlined';
  font-weight: normal;
  font-style: normal;
  font-size: 24px; /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  font-variation-settings:
    'FILL' 0,
    'wght' 400,
    'GRAD' 0,
    'opsz' 24
}

.text-break-all {word-break: break-all;}

.s-48,
img.s-48 {width: 48px; height: 48px;}

.w-30 {width: 30%;}
.w-60 {width: 60%;}
.w-40 {width: 40%;}

.pagination-container {
  display: flex;
  justify-content: space-between;
  margin: 0;
}

@media screen and (max-width: 768px) {
  .pagination-container { justify-content: center;}
}
