// Flatly 5.0.2
// Bootswatch

$theme: "flatly" !default;

//
// Color system
//

$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #ecf0f1 !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #b4bcc2 !default;
$gray-600: #95a5a6 !default;
$gray-700: #7b8a8b !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;

$blue: #2c3e50 !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #f5115b !default;
$red: #e74c3c !default;
$orange: #fd7e14 !default;
$yellow: #f39c12 !default;
$green: #18bc9c !default;
$teal: #20c997 !default;
$cyan: #3498db !default;

$gray-dark: rgb(123, 138, 139) !default;
$gray-light: rgb(180, 188, 194) !default;
$gray-lighter: rgb(236, 240, 241) !default;
$pink-chippix: #f5115b !default;
$pink-chippix-dark: #d00158 !default;

$primary: rgb(52, 43, 79) !default;
$secondary: $gray-600 !default;
$success: rgb(24, 188, 156) !default;
$info: rgb(0, 172, 237) !default;
$warning: rgb(243, 156, 18) !default;
$danger: $pink-chippix-dark !default;
$light: rgba(249,250,251) !default;
$dark: $gray-800 !default;
$tertiary: #5260ff !default;

$min-contrast-ratio: 2.05 !default;

// theme custom colors

$theme-colors: (
  "pink-chippix": $pink-chippix,
  "pink-chippix-dark": $pink-chippix-dark,
  "gray-dark": $gray-dark,
  "gray-light": $gray-light,
  "gray-lighter": $gray-lighter,
  "indigo": $indigo,
  "tertiary": $tertiary,
);

// Links

$link-color: $pink-chippix-dark !default;

// Fonts

// stylelint-disable-next-line value-keyword-case
$font-family-sans-serif: Lato, -apple-system, BlinkMacSystemFont, "Segoe UI",
  Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
  "Segoe UI Emoji", "Segoe UI Symbol" !default;
$h1-font-size: 3rem !default;
$h2-font-size: 2.5rem !default;
$h3-font-size: 2rem !default;

// Tables

$table-bg-scale: 0 !default;

// Dropdowns

$dropdown-link-color: $gray-700 !default;
$dropdown-link-hover-color: $white !default;
$dropdown-link-hover-bg: $primary !default;

// Navs

$nav-link-padding-y: 0.5rem !default !default;
$nav-link-padding-x: 2rem !default;
$nav-link-disabled-color: $gray-600 !default !default;
$nav-tabs-border-color: $gray-200 !default;

// Navbar

$navbar-padding-y: 1rem !default;
$navbar-dark-color: $white !default;
$navbar-dark-hover-color: $primary !default;
$navbar-dark-active-color: $primary !default;

$navbar-inverse-bg: $red !default;

$navbar-dark-brand-color: $white !default;
$navbar-dark-brand-hover-color: $navbar-dark-brand-color !default;

// Pagination

$pagination-color: $white !default;
$pagination-bg: $gray-dark !default;
$pagination-border-width: 0 !default;
$pagination-border-color: transparent !default;
$pagination-hover-color: $white !default;
$pagination-hover-bg: darken($primary, 8%) !default;
$pagination-hover-border-color: transparent !default;
$pagination-active-bg: $primary !default;
$pagination-active-border-color: transparent !default;
$pagination-disabled-color: $gray-dark !default;
$pagination-disabled-bg: $gray-lighter !default;
$pagination-disabled-border-color: transparent !default;

// List group

$list-group-hover-bg: $gray-200 !default;
$list-group-disabled-bg: $gray-200 !default;

// Breadcrumbs

$breadcrumb-padding-y: 0.375rem !default;
$breadcrumb-padding-x: 0.75rem !default;
$breadcrumb-border-radius: 0.25rem !default;

// Close

$btn-close-color: $white !default;
$btn-close-opacity: 0.4 !default;
$btn-close-hover-opacity: 1 !default;
